import { Box, Stack, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function WinnersBanner() {
  return (
    <Box
      // w={'100vw'}
      background={'#d8e8fa'}
    >
      <VStack minH={["","50vh","70vh"]}  p={5}>
        <CustomNextImage
          src={'/imgs/sbt_games/text_winners_banner.png'}
          alt={`Text Winners`}
          width={'1071px'}
          height={'78px'}
        />
        <Stack minH={["","40vh","60vh"]} direction={["column","row"]}>
          <Box alignSelf={"end"}>
            <CustomNextImage
              src={'/imgs/sbt_games/winner_2.png'}
              alt={`Winner 2`}
              width={'378px'}
              height={'425px'}
            />
          </Box>
          <Box alignSelf={"start"}>
            <CustomNextImage
              src={'/imgs/sbt_games/winner_3.png'}
              alt={`Winner 2`}
              width={'378px'}
              height={'425px'}
            />
          </Box>
          <Box alignSelf={"end"}>
            <CustomNextImage
              src={'/imgs/sbt_games/winner_1.png'}
              alt={`Winner 1`}
              width={'382px'}
              height={'425px'}
            />
          </Box>
        </Stack>
      </VStack>
    </Box>
  );
}
