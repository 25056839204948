import {
  Box,
  HStack,
  VStack,
} from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function ParticipateBanner() {
  return (
    <Box
      // w={'100vw'}
      backgroundImage={"url('/imgs/sbt_games/background_banner_participate.png')"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      id='participate_plans'
    >
      <VStack p={5}>
        <CustomNextImage
            src={"/imgs/sbt_games/text_plans_banner.png"}
            alt={`Text Plans`}
            width={'965px'}
            height={'71px'}
          />
        <HStack>
          <CustomNextImage
            src={"/imgs/sbt_games/plan1.png"}
            alt={`Plan 1`}
            width={'334px'}
            height={'430px'}
          />
          <CustomNextImage
            src={"/imgs/sbt_games/plan2.png"}
            alt={`Plan 2`}
            width={'333px'}
            height={'430px'}
          />
          <CustomNextImage
            src={"/imgs/sbt_games/plan3.png"}
            alt={`Plan 3`}
            width={'334px'}
            height={'430px'}
          />
          <CustomNextImage
            src={"/imgs/sbt_games/plan4.png"}
            alt={`Plan 4`}
            width={'334px'}
            height={'430px'}
          />
        </HStack>
      </VStack>
    </Box>
  );
}
