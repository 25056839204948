import { Box, HStack, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function PrizesBanner() {
  return (
    <Box
      // w={'100vw'}
      backgroundImage={"url('/imgs/sbt_games/background_banner_prizes.png')"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <VStack pt={5} px={2}>
        <Box>
          <CustomNextImage
            src={'/imgs/sbt_games/text_prizes_banner.png'}
            alt={`Text Prizes`}
            width={'965px'}
            height={'71px'}
          />
        </Box>
        <HStack h="120px">
          <CustomNextImage
            src={'/imgs/sbt_games/dont_waste_time.png'}
            alt={`stars left`}
            width={'386px'}
            height={'454px'}
          />
          <HStack>
            <CustomNextImage
              src={'/imgs/sbt_games/step_1.png'}
              alt={`stars left`}
              width={'240px'}
              height={'82px'}
            />
            <CustomNextImage
              src={'/imgs/sbt_games/step_2.png'}
              alt={`stars left`}
              width={'240px'}
              height={'82px'}
            />
            <CustomNextImage
              src={'/imgs/sbt_games/step_3.png'}
              alt={`stars left`}
              width={'240px'}
              height={'82px'}
            />
          </HStack>

          <CustomNextImage
            src={'/imgs/sbt_games/its_easy.png'}
            alt={`stars right`}
            width={'325px'}
            height={'307px'}
          />
        </HStack>

        <VStack >
          <CustomNextImage
            src={'/imgs/sbt_games/banner_prizes_explanation.png'}
            alt={`Prizes Header`}
            width={'1440px'}
            height={'343px'}
          />
        </VStack>
      </VStack>
    </Box>
  );
}
