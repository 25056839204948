import HeadingBanner from './components/HeaderBanner';
import ParticipateBanner from './components/PartipateBanner';
import PrizesBanner from './components/PrizesBanner';
import WinnersBanner from './components/WinnersBanner';

export default function SBTGamesModule() {

  return (
    <>
      <HeadingBanner
      />
      <ParticipateBanner />
      <PrizesBanner
      />

      <WinnersBanner
      />
    </>
  );
}
