import { Box, HStack, VStack } from '@chakra-ui/react';
import CustomNextImage from '@components/ChakraNextImage';

export default function HeadingBanner() {
  return (
    <Box
      // w={'100vw'}
      backgroundImage={"url('/imgs/sbt_games/background_banner_header.png')"}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <VStack spacing={'10px'} px={2}>
        <HStack align={"start"} mb={{ base: -8, md: -10, lg: -20 }}>
          <Box >
            <CustomNextImage
              src={'/imgs/sbt_games/subscribe_now_left.png'}
              alt={`subscribe now left`}
              width={'320px'}
              height={'321px'}
            />
          </Box>
          <CustomNextImage
            src={'/imgs/sbt_games/stars_left.png'}
            alt={`stars left`}
            width={'265px'}
            height={'163px'}
          />
          <CustomNextImage
            src={'/imgs/sbt_games/sbt_logo.png'}
            alt={`sbt logo`}
            width={'315px'}
            height={'200px'}
          />
          <CustomNextImage
            src={'/imgs/sbt_games/stars_right.png'}
            alt={`stars right`}
            width={'265px'}
            height={'163px'}
          />
          <Box>
            <CustomNextImage
              src={'/imgs/sbt_games/subscribe_now_right.png'}
              alt={`subscribe now right`}
              width={'320px'}
              height={'319px'}
            />
          </Box>
        </HStack>
        <Box>
          <CustomNextImage
            src={'/imgs/sbt_games/text_header_banner.png'}
            alt={`Text Header`}
            width={'909px'}
            height={'86px'}
          />
        </Box>
        <VStack>
          <CustomNextImage
            src={'/imgs/sbt_games/prizes_header_banner.png'}
            alt={`Prizes Header`}
            width={'1440px'}
            height={'297px'}
          />
        </VStack>
      </VStack>
    </Box>
  );
}
